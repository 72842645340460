import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, Image, Pagination, QRCode } from 'antd'
import useHttp from '../../../../hooks/use-http';
import ReactToPrint from 'react-to-print';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import logo from '../../../../asset/logos/icon.svg'
import Search from 'antd/es/input/Search';
import ValetCoupon from '../../../../component/common/ValetCoupon/ValetCoupon';

const QRList = () => {
    const [qrcodeData, setQrcodeData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(12);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectQRCode, setSelectQRCode] = useState({});
    const [userInput, setUserInput] = useState("");
    const [clubDetails,setClubDetails] = useState({})
    const api = useHttp();
    const componentRef = useRef();
    const selectedRef = useRef();



    const selectedQR = (e) => {
        const id = e.target.id
        const payload = {}
        payload[id] = e.target.checked
        setSelectQRCode(prev => {
            // console.log(prev);

            return {
                ...prev,
                ...payload
            }


        })
    }

    const searchHandler = (e) => {
        // console.log(e.target.value.toLowerCase());
        setUserInput(e.target.value.toLowerCase());

    };

    const filterData = qrcodeData?.filter(
        (ele) =>
            userInput.trim() === "" ||
            JSON.stringify(ele)?.toLowerCase()?.includes(userInput)
    );



    const handleChangePage = (page, pageSize) => {

        setCurrentPage(page);
        setPageSize(pageSize);
    };

    useEffect(() => {
        const GET_QRCODE_API = { ...CONSTANTS.API.getQRcode };
        const CLUB_PROFILE_API = { ...CONSTANTS.API.getMyProfile };
        GET_QRCODE_API.endpoint = `${GET_QRCODE_API.endpoint}?page=${currentPage}&limit=${pageSize}`


        api.sendRequest(GET_QRCODE_API, (res) => {
            setTotalRecords(res?.totalData)
            setQrcodeData(res?.data?.qrs)
            setSelectQRCode({});
        });

        api.sendRequest(CLUB_PROFILE_API, (res) => {
            setClubDetails(res?.data?.club);
          });



    }, [pageSize, currentPage])


    return (
        <>
            <Card>

                <Search placeholder="Search..." className='w-1/2 block' onChange={searchHandler} />

                {qrcodeData.length !== 0 && <ReactToPrint
                    trigger={() => <Button
                        onClick={() => {

                        }}
                        className="mt-5 mx-3"
                        type="primary"
                    >
                        Download All QRCode
                    </Button>}
                    content={() => componentRef.current}
                />}
                {Object.values(selectQRCode).includes(true) && <ReactToPrint
                    trigger={() => <Button
                        onClick={() => {

                        }}
                        className="mt-5 "
                        type="primary"
                    >
                        Download Selected {Object.values(selectQRCode).reduce((count, value) => count + (value === true ? 1 : 0), 0)}
                    </Button>}
                    content={() => selectedRef.current}
                />}

                {/* Displayed QRCode  */}
                <div className='my-5 ' >

                    {filterData?.map((data) => <div key={data?.id} className='inline-block m-2 couponContainer'>
                        <div className='valetCoupon'>
                            <Checkbox className='ml-3 mt-2' id={`chk${data?.id?.toString()}`} name={`chkbx${data?.id?.toString()}`} onChange={selectedQR} />
                            <ValetCoupon data = {data} clubDetails={clubDetails} />
                        </div>

                    </div>)}

                </div>
                {/* All Download QRCode */}
                <div className='hidden'>
                    <div className='my-0 ' ref={componentRef}>

                        {filterData?.map((data) => <div key={data?.id} className='inline-block my-2 mx-4 couponContainer'>
                            <div className='bg-black rounded-lg valetCoupon'>
                                <Checkbox className='ml-3 mt-0 mt-2' id={`chk${data?.id?.toString()}`} name={`chkbx${data?.id?.toString()}`} onChange={selectedQR} />
                                <ValetCoupon data = {data} clubDetails={clubDetails} />
                            </div>

                        </div>)}

                    </div>
                </div>

                {/* Selecetd QRCode */}
                <div className='' >
                    <div className='mx-4 flex flex-wrap gap-x-2 gap-y-2' ref={selectedRef}>

                        {qrcodeData?.map((data) => <div key={data?.id} className='couponContainer'>
                            {selectQRCode[`chk${data?.id.toString()}`] && <div className='bg-black w-40  rounded-lg valetCoupon'>
                            <Checkbox className='ml-3 mt-0 mt-2' id={`chk${data?.id?.toString()}`} name={`chkbx${data?.id?.toString()}`} onChange={selectedQR} />
                            <ValetCoupon data = {data} clubDetails={clubDetails} />
                            </div>}

                        </div>)}

                    </div>
                </div>

                {userInput === '' && <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalRecords}
                    showSizeChanger
                    pageSizeOptions={[12, 24, 50, 100, 200, 500]}
                    onChange={handleChangePage}
                    className="mt-16"
                />}
            </Card>
        </>
    )
}

export default QRList